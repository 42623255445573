<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo Cliente - Ver</strong>
            <router-link  class="btn btn-light float-right btn-sm"  :to="{ path: '/cliente/listar' }" append ><i class="fa fa-chevron-left"></i> Regresar</router-link>
          </CCardHeader>
          <CCardBody>
            <b-form id="Form" autocomplete="off">
              <b-tabs content-class="mt-3">
                <b-tab title="Datos del Cliente" active>
                  <b-row>
                    <b-col md="2">
                      <b-form-group label="Tipo de Documento:">
                        <b-form-select disabled v-model="client.document_type" :options="document_type"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Nro Documento:">
                        <b-input-group>
                          <b-form-input disabled v-model="client.document_number" class="form-control" ></b-form-input>
                        </b-input-group>
                      </b-form-group>
                    </b-col>

                    <b-col md="8">
                      <b-form-group label="Nombres:">
                        <b-form-input disabled type="text"  v-model="client.name"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Fecha de nacimiento :">
                        <b-form-input disabled type="date"  v-model="client.birth_date"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Sexo:">
                        <b-form-select disabled v-model="client.sex" :options="sex"></b-form-select>
                      </b-form-group>
                    </b-col>
                    
                    <b-col md="2">
                      <b-form-group label="Estado Civil:">
                        <b-form-select disabled v-model="client.civil_status" :options="civil_status"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Profesión y/o Ocupación:">
                        <b-form-select disabled v-model="client.profession" :options="profession"></b-form-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Telefono :">
                        <b-form-input disabled type="text"  v-model="client.phone"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Celular :">
                        <b-form-input disabled type="text"  v-model="client.cell_phone"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Email :">
                        <b-form-input disabled type="email"  v-model="client.email"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Ubigeo :">
                        <v-select disabled placeholder="Seleccione un ubigeo" label="text" class="w-100" v-model="mubigee" :options="ubigee"></v-select>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Dirección :">
                        <b-form-input disabled type="text"  v-model="client.address"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="4">
                      <b-form-group label="Dirección Secundaria:">
                        <b-form-input disabled type="text"  v-model="client.secondary_address"></b-form-input>
                      </b-form-group>
                    </b-col>

                    <b-col md="6">
                      <b-form-group label="Observación :">
                        <b-form-textarea disabled v-model="client.observation"></b-form-textarea>
                      </b-form-group>
                    </b-col>

                    <b-col md="2">
                      <b-form-group label="Estado:">
                        <b-form-select disabled v-model="client.state" :options="state"></b-form-select>
                      </b-form-group>
                    </b-col>

                  </b-row>
                </b-tab>
                <b-tab title="Datos del Conyuge">
                  <b-row>
                    <b-col md="12">
                      <div class="table-responsive mt-3 height-table">
                        <table class="table table-hover table-bordered">
                          <thead>
                            <tr>
                              <th width="3%" class="text-center">#</th>
                              <th width="15%" class="text-center">Parentesco</th>
                              <th width="10%" class="text-center">Tipo Doc.</th>
                              <th width="15%" class="text-center">Nro Doc.</th>
                              <th width="50%" class="text-center">Nombre</th>
                            </tr>
                          </thead>
                          <tbody v-for="(item, it) in client.relatives" :key="it">
                            <tr>
                              <td class="text-center">{{ it + 1 }}</td>
                              <td class="text-left">{{ NameRelationship(item.relationship) }}</td>
                              <td class="text-left">{{ CodeDocumentType(item.document_type) }}</td>
                              <td class="text-left">{{ item.document_number}}</td>
                              <td class="text-left">{{ item.name}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </b-col>

                  </b-row>
                </b-tab>
              </b-tabs>

              
            </b-form>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>


    <LoadingComponent :is-visible="isLoading"/>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
var moment = require("moment");

import { mapState,mapMutations,mapActions } from "vuex";
import EventBus from "@/assets/js/EventBus";
// components
import CodeToName from "@/assets/js/CodeToName";
import LoadingComponent from './../pages/Loading'
export default {
  name: "ClientEdit",
  props: ["id_client"],
  components:{
      vSelect,
      Keypress: () => import('vue-keypress'),
      LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: 'Client',
      role: 5,
      client: {
        id_client:'',
        id_branch_office:'',
        document_type:1,
        document_number:'',
        name:'',
        birth_date:'',
        sex:'',
        phone:'',
        cell_phone:'',
        profession:'',
        email:'',
        ubigee:'',
        address:'',
        secondary_address:'',
        observation:'',
        now_date: moment(new Date()).local().format("YYYY-MM-DD"),
        state:1,
        relatives:[],
      },
      civil_status:[
        {value:"", text:"-- Seleccione --"},
        {value:"1", text:"Soltero"},
        {value:"2", text:"Casado"},
        {value:"3", text:"Viudo"},
        {value:"4", text:"Divorciado"},
        {value:"5", text:"Conviviente"},
      ],
      state:[
        {value:'1',text:'Activo'},
        {value:'0',text:'Inactivo'},
      ],
      mubigee:null,
      ubigee: [],
      sex:[
        {value: '', text:'- Seleccione -'},
        {value: 'Hombre', text:'Hombre'},
        {value: 'Mujer', text:'Mujer'},
      ],
      mubigee:null,
      ubigee: [],
      error_document_number:'',
      profession:[
        { value:'', text: '- Seleccione -'},
        { value:'Comerciante Formal', text: 'Comerciante Formal'},
        { value:'Comerciante Informal', text: 'Comerciante Informal'},
        { value:'Profesional', text: 'Profesional'},
        { value:'Agricultor', text: 'Agricultor'},
        { value:'Otros', text: 'Otros'},
      ],

      document_type: [
        {value: 1 , text : 'DNI'},
        {value: 6 , text : 'RUC'},
        {value: 4 , text : 'CARNET DE EXTRANJERIA'},
        {value: 7 , text : 'PASAPORTE'},
        {value: 0 , text : 'OTROS'},
      ],
      relationship:[
        { value : '', text : '-- Seleccione --' },
        { value : '1', text: 'ESPOSO (A)' },
        { value : '2', text: 'HIJO (A)' },
        { value : '3', text: 'PADRE' },
        { value : '4', text: 'MADRE' },
        { value : '5', text: 'TIO (A)' },
        { value : '6', text: 'SOBRINO (A)' },
        { value : '7', text: 'ABUELO (A)' },
        { value : '8', text: 'HERMANO (A)' },
        { value : '9', text: 'CONYUGE' },
        { value : '10', text: 'PRIMO (A)' },
        { value : '11', text: 'OTROS' },
      ],

      errors: {
        document_number:false,
        name: false,
      },
      validate: false,
    };
  },
  created(){
    this.ListUbigeos();
  },
  mounted() {
    this.ViewClient();
  },
  methods: {
    ViewClient,
    ListUbigeos,
    CodeDocumentType,
    NameRelationship,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
    user: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user;
    },
  },
};

function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code.toString());
}

function ListUbigeos() {
  this.ubigee = [];
  let url = this.url_base + "list-ubigee";
  let me = this;
  axios({
      method: "GET",
      url: url,
    }).then(function (response) {
      for (let index = 0; index < response.data.length; index++) {
      const element = response.data[index];
      me.ubigee.push({value:element.id_ubigee,text:element.department+', '+element.province+', '+element.district })
    }
  })
}

function ViewClient() {
  let me = this;
  let id_client = je.decrypt(this.id_client);
  let url = me.url_base + "client/view/"+id_client;
  me.isLoading = true;
  axios({
    method: "GET",
    url: url,
    headers: { "Content-Type": "application/json", token: me.token, module: me.module,role: me.role },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.client.id_client = response.data.result.id_client;
        me.client.id_branch_office = response.data.result.id_branch_office;
        me.client.document_type = response.data.result.document_type;
        me.client.document_number = response.data.result.document_number;
        me.client.name = response.data.result.name;
        me.client.birth_date = response.data.result.birth_date;
        me.client.civil_status = response.data.result.civil_status;
        me.client.sex = response.data.result.sex;
        me.client.phone = response.data.result.phone;
        me.client.cell_phone = response.data.result.cell_phone;
        me.client.profession = response.data.result.profession;
        me.client.email = response.data.result.email;
        me.client.ubigee = response.data.result.ubigee;
        me.client.address = response.data.result.address;
        me.client.secondary_address = response.data.result.secondary_address;
        me.client.observation = response.data.result.observation;
        me.client.state = response.data.result.state;
        me.mubigee = response.data.result.ubigee_value;

        me.client.relatives = response.data.result.relatives;
        
      }else{
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
      me.isLoading = false;
    })
    .catch((error) => {
      console.log(error)
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      me.isLoading = true;
    });
}

function NameRelationship(code) {
  for (let index = 0; index < this.relationship.length; index++) {
    const element = this.relationship[index];
    if (element.value == code) {
      return element.text;
    }
  }
}
</script>
